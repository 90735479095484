import Dashboard from "./layouts/dashboard";
import Tables from "./layouts/tables";
import AddSchool from "./layouts/addschool";
import Permission from "./layouts/staffPermision";

import Icon from "@mui/material/Icon";
import AddAdmin from "./layouts/AddAdmin/AddAdmin";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  // {
  //   type: "collapse",
  //   name: "Permission",
  //   key: "Permission",
  //   icon: <Icon fontSize="small">AccountBalanceIcon</Icon>,
  //   route: "/Permission",
  //   component: <Permission />,
  // },
  // {
  //   type: "collapse",
  //   name: "Add School",
  //   key: "AddSchool",
  //   icon: <Icon fontSize="small">AccountBalanceIcon</Icon>,
  //   route: "/AddSchool",
  //   component: <AddSchool />,
  // },
  {
    type: "collapse",
    name: "Add Admin",
    key: "AddAdmin",
    icon: <Icon fontSize="small">AccountBalanceIcon</Icon>,
    route: "/AddAdmin",
    component: <AddAdmin />,
  },
  {
    type: "collapse",
    name: "Tables",
    key: "tables",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/tables",
    component: <Tables />,
  },
  // {
  //   type: "collapse",
  //   name: "Billing",
  //   key: "billing",
  //   icon: <Icon fontSize="small">receipt_long</Icon>,
  //   route: "/billing",
  //   component: <Billing />,
  // },
  // {
  //   type: "collapse",
  //   name: "RTL",
  //   key: "rtl",
  //   icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
  //   route: "/rtl",
  //   component: <RTL />,
  // },
  // {
  //   type: "collapse",
  //   name: "Notifications",
  //   key: "notifications",
  //   icon: <Icon fontSize="small">notifications</Icon>,
  //   route: "/notifications",
  //   component: <Notifications />,
  // },
  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/profile",
  //   component: <Profile />,
  // },
  // {
  //   type: "collapse",
  //   name: "Sign In",
  //   key: "sign-in",
  //   icon: <Icon fontSize="small">login</Icon>,
  //   route: "/authentication/sign-in",
  //   component: <SignIn />,
  // },
  // {
  //   type: "collapse",
  //   name: "Sign Up",
  //   key: "sign-up",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/authentication/sign-up",
  //   component: <SignUp />,
  // },
];

export default routes;
