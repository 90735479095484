import { useState, useEffect, useMemo } from "react";
import "./App.css";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

import MDBox from "components/MDBox";
import Sidenav from "examples/Sidenav";

import Configurator from "examples/Configurator";
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";


import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import Dashboard from "layouts/dashboard";

import routes from "routes";
import AddSchool from "layouts/addschool";

import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";

import ProtectedRoute from "layouts/authentication/ProtectedRoute";
import LoginRoute from "layouts/authentication/LoginRoute";

import Permission from "layouts/staffPermision";
import SignIn from "layouts/authentication/sign-in";
import AddAdmin from "./layouts/AddAdmin/AddAdmin";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  const [isSignedIn, setIsSignedIn] = useState(null)

  // Cache for the rtl
  // useMemo(() => {
  //   // const cacheRtl = createCache({
  //   //   key: "rtl",
  //   //   stylisPlugins: [rtlPlugin],
  //   });

  //  // setRtlCache(cacheRtl);
  // }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
   // document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {

    
   setIsSignedIn(localStorage.getItem("LoginStatus"));

   console.log("loginStatus",localStorage.getItem("LoginStatus"));
   // document.documentElement.scrollTop = 0;
  //  document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {}, []);



  const getRoutes = (allRoutes) =>
 
  allRoutes.map((route) => {
    console.log("aaaaaa", route.route)
    if (route.collapse) {
      return getRoutes(route.collapse);
    }

    if (route.route) {
      if(route.component=="authentication/sign-in")
      {
        return <Route exact path={route.route} element={<LoginRoute isSignedIn={isSignedIn}>{route.component}</LoginRoute> } key={route.key} />;
      }
      else
      return <Route exact path={route.route} element={<ProtectedRoute isSignedIn={isSignedIn}>{route.component}</ProtectedRoute> } key={route.key} />;
     
    }

    return null;
  });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brandName="UBK Pro Admin"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {configsButton}
        </>
      )}
      <Routes>
      
        <Route exact path="/" element={<LoginRoute isSignedIn={isSignedIn}><SignIn/></LoginRoute>} />
       <Route path="/dashboard" element={ <ProtectedRoute isSignedIn={isSignedIn}><Dashboard /></ProtectedRoute> } />
       <Route path="/Permission" element={ <ProtectedRoute isSignedIn={isSignedIn}><Permission /></ProtectedRoute> } />
       <Route path="/AddSchool" element={ <ProtectedRoute isSignedIn={isSignedIn}><AddSchool /></ProtectedRoute> } />
       <Route path="/AddAdmin" element={ <ProtectedRoute isSignedIn={isSignedIn}><AddAdmin /></ProtectedRoute> } />

      </Routes>
    </ThemeProvider>
  );
}
