// @mui material components
import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";


// TABLE
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

// DROPDOWN
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";


// ALERT
import Swal from "sweetalert2";

// API
// import { url } from "../../services/config";


// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import Paper from "@mui/material/Paper";
import useSWR, { mutate } from "swr";
import useSWRMutation from "swr/mutation";


// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";

import { addData, getNewData, menulistUrl, roleslistUrl, adminDropdownUrl, addDataTigget, updateData } from "../../Api/api";
import { useRef } from "react";
import { NativeSelect } from "@mui/material";


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
function Permission() {
  // API

  const formRef = useRef(null);
  // const resetbtn = useRef(null);


  // HOOKS FOR FORMS
  const [isSelected, setIsSelected] = useState(false);
  const [formKey, setFormKey] = useState(false);
  const [selectUrl, setSelectUrl] = useState(false);


  const [formData, setFormData] = useState({
    role_id: "",
    permission: [],
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    // if(resetbtn?.current){
    //   resetbtn.current.click();
    // }
    setSelectUrl(`/superAdmin/getadmindata?id=${event.target.value}`);
    setFormKey((prev) => !prev);
    setIsSelected(true);
  };

  const {
    isLoading: isLoadingMenu,
    error: errorMenu,
    data: menuItem,
  } = useSWR(menulistUrl, addData);
  const {
    isLoading: isLoadingRole,
    error: errorRole,
    data: adminDropDown,
  } = useSWR(adminDropdownUrl, getNewData);

  const {
    isLoading: isLoadingAdminData,
    error: adminDataError,
    data: adminData,
    mutate: mutateAdmin,
  } = useSWR(selectUrl, getNewData);


  // Step 2: Define the handleSubmit function
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    const frmData = new FormData(e.target);
    if (!valiDate()) {
      failedAlert("Please select any field");
      return;
    }
    try {
      const res = await mutate("tablekey", updateData("/superAdmin/addpermission", frmData));
      if (res.Status === "Success") {
        successAlert();
      }
    }
    catch (err) {
      failedAlert("Failed");
      console.log(err)
    }


  };
  const failedAlert = (title) => {
    Swal.fire({
      icon: "error",
      title: title,
      showConfirmButton: true,
    });
  };

  const successAlert = () => {
    Swal.fire({
      icon: "success",
      title: "Successful",
      showConfirmButton: false,
      timer: 2000,
    });
  };


  const checkFunc = (index) => {
    const inptArr = formRef.current[`permission[${index}][data][]`];
    for (let i = 0; i < inptArr.length; i++) {
      if (inptArr[i].checked) {
        return false
      }
    }
    return true;
  }
  const valiDate = () => {
    for (let i = 0; i < menuItem.length; i++) {
      if (!checkFunc(i)) {
        return true
      }
    }
    return false
  }
  const handleFormChange = (e) => {
    const index = e.target.name.slice(11, -9);
    formRef.current[`permission[${index}][menu_id]`].disabled = checkFunc(index);
  }

  return (
    <DashboardLayout>
      <MDBox pt={8} pb={1}>
        <Grid container spacing={6}>
          {!isLoadingMenu && !isLoadingRole &&
            <form ref={formRef} key={`formKey01${formKey}`} onChange={handleFormChange} style={{ width: "100%" }} onSubmit={handleSubmit}>
              {/* <input type="reset" ref={resetbtn} style={{ display: "none" }} /> */}
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={1}
                    px={1}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      Roles Permission
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={3} pl={2} pr={2}>
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={3}></Grid>

                        <Grid item xs={12} md={6}>
                          <MDBox >
                            <MDBox>
                              <FormControl fullWidth>

                              {/* <label className="labelfont">Role</label>
                                <NativeSelect
                                  defaultValue={""}
                                  inputProps={{
                                    name: 'role_id',
                                    id: 'admin01',
                                  }}
                                >
                                  <option value={""} disabled>Select</option>
                                  {adminDropDown?.data?.map((item, index) => (
                                    <option key={`role01${index}`} value={item.value}>
                                      {item.label}
                                    </option>
                                  ))}
                                </NativeSelect> */}

                                <InputLabel id="demo-simple-select-label">Role</InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  label="Role"
                                  name="role_id"
                                  value={formData.role_id}
                                  onChange={handleChange}
                                  sx={{ height: "44px" }}
                                >
                                  {adminDropDown?.data?.map((item, index) => (
                                    <MenuItem key={`role01${index}`} value={item.value}>
                                      {item.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </MDBox>
                          </MDBox>
                        </Grid>
                        <Grid item md={3} xs={12}></Grid>
                      </Grid>
                    </Box>
                  </MDBox>

                  {isSelected && (
                    <MDBox pt={3} pl={2} pr={2}>
                      <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={1}>
                          <Grid item xs={12} md={1}></Grid>
                          <Grid item xs={12} md={10}>
                            <TableContainer component={Paper}>
                              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                  <TableRow sx={{ paddingLeft: 2 }}>
                                    <TableCell sx={{ width: "30%", padding: 0, paddingLeft: 1 }}>
                                      Menu
                                    </TableCell>
                                    <TableCell sx={{ width: "6%", padding: 0 }} align="center">
                                      Add
                                    </TableCell>
                                    <TableCell sx={{ width: "6%", padding: 0 }} align="center">
                                      View
                                    </TableCell>
                                    <TableCell sx={{ width: "6%", padding: 0 }} align="center">
                                      Delete
                                    </TableCell>
                                    <TableCell sx={{ width: "6%", padding: 0 }} align="center">
                                      Edit
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {menuItem?.map((row, index) => (
                                    <TableRow
                                      key={`menuItem1${index}`}
                                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                    >
                                      <TableCell sx={{ padding: 0, paddingLeft: 1 }} scope="row">
                                        {row.name}
                                      </TableCell>
                                      <TableCell sx={{ padding: 0 }} align="center">
                                        <input value={row.menu_id} name={`permission[${index}][menu_id]`} onChange={() => { }} disabled style={{ display: "none" }} />
                                        <input
                                          type="checkbox"
                                          name={`permission[${index}][data][]`}
                                          value={"add"}
                                        // checked={checkboxValues.add}
                                        // onChange={() => handleChangeCheckbox(row.menu_id, "add")}
                                        />
                                      </TableCell>
                                      <TableCell sx={{ padding: 0 }} align="center">
                                        <input
                                          type="checkbox"
                                          name={`permission[${index}][data][]`}
                                          value={"view"}
                                        // checked={checkboxValues.view}
                                        // onChange={() => handleChangeCheckbox(row.menu_id, "view")}
                                        />
                                      </TableCell>
                                      <TableCell sx={{ padding: 0 }} align="center">
                                        <input
                                          type="checkbox"
                                          name={`permission[${index}][data][]`}
                                          value={"delete"}
                                        // checked={checkboxValues.delete}
                                        // onChange={() => handleChangeCheckbox(row.menu_id, "delete")}
                                        />
                                      </TableCell>
                                      <TableCell sx={{ padding: 0 }} align="center">
                                        <input
                                          type="checkbox"
                                          name={`permission[${index}][data][]`}
                                          value={"edit"}
                                        // checked={checkboxValues.edit}
                                        // onChange={() => handleChangeCheckbox(row.menu_id, "edit")}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>

                          <Grid item xs={12} md={1}></Grid>
                        </Grid>
                      </Box>
                    </MDBox>
                  )}
                  <MDBox pt={3} mb={2}>
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={3}></Grid>
                        <Grid
                          item
                          xs={6}
                          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                        >
                          {isSelected &&
                            <Button
                              type="submit"
                              variant="contained"
                              name="subBtn"
                              // onClick={handleSubmit}
                              style={{ color: "white", fontSize: "14px", minWidth: "162px" }}
                            >
                              Save
                            </Button>
                          }

                        </Grid>
                        <Grid item xs={3}></Grid>
                      </Grid>
                    </Box>
                  </MDBox>
                </Card>
              </Grid>
            </form>
          }
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Permission;
