import React, { useState, useContext } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "../../components/MDBox";

import MDTypography from "../../components/MDTypography";
import Card from "@mui/material/Card";
import MDInput from "../../components/MDInput";

import UBKSubmit from "../../components/UBKSubmit";

import { RowId } from "../../components/Table/UBKPaginationTable";

// API
import { ConfigFile } from "../../services/ConfigFile";

import useSWR from "swr";
//import MDButton from "components/MDButton";

import { getNewData, getadmindataUrl as cacheKey } from "../../Api/api";
import UBKCheckbox from "../../components/UBKCheckbox";

function EditAdmin(props) {
    const { handleUpdate, id: idd } = props.data;
    // const idd = useContext(RowId);

    const {
        isLoading,
        error,
        data: newData,
        mutate,
    } = useSWR(`${cacheKey}?id=${idd}`, getNewData);

    const {
        isLoading: menuLoading,
        error: menuError,
        data: permissionMenu
    } = useSWR("/permission/Menu", getNewData);



    return (
        <MDBox pt={8} pb={1} style={{ width: 350 }}>
            <Grid container spacing={6} >
                <Grid item xs={12}>
                    <Card>
                        <MDBox
                            mx={2}
                            mt={-3}
                            py={1}
                            px={1}
                            variant="gradient"
                            bgColor="info"
                            borderRadius="lg"
                            coloredShadow="info"
                        >
                            <MDTypography variant="h6" color="white">
                                Admion Edit
                            </MDTypography>
                        </MDBox>
                        <MDBox p={3}>
                            <form onSubmit={handleUpdate}>
                                <Grid container spacing={3}>
                                    <MDInput
                                        type="text"
                                        defaultValue={idd}
                                        name="id"
                                        required="required"
                                        className="noDisplay"
                                    />
                                    <Grid item xs={12}>
                                        <MDBox>
                                            <MDBox>
                                                <MDInput
                                                    defaultValue={newData?.data[0]?.first_name}
                                                    name="first_name"
                                                    type="text"
                                                    required="required"
                                                    label="First Name"
                                                />
                                            </MDBox>
                                        </MDBox>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MDBox>
                                            <MDBox>
                                                <MDInput
                                                    defaultValue={newData?.data[0]?.last_name}
                                                    name="last_name"
                                                    type="text"
                                                    required="required"
                                                    label="Last Name"
                                                />
                                            </MDBox>
                                        </MDBox>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MDBox>
                                            <MDBox>
                                                <MDInput
                                                    defaultValue={newData?.data[0]?.email_id}
                                                    name="email_id"
                                                    type="email"
                                                    required="required"
                                                    label="Email"
                                                />
                                            </MDBox>
                                        </MDBox>
                                    </Grid>
                                    {/* <Grid item xs={12}>
                                        <MDBox>
                                            <MDBox>
                                                <MDInput
                                                    name="password"
                                                    type="password"
                                                   
                                                    label="Password"
                                                />
                                            </MDBox>
                                        </MDBox>
                                    </Grid> */}
                                    <Grid item xs={12}>
                                        <MDBox>
                                            <MDBox>
                                                {newData && permissionMenu &&
                                                    <UBKCheckbox
                                                        label="Permissions"
                                                        defaultValue={newData?.data[0]?.permission}
                                                        options={Array.isArray(permissionMenu) ? permissionMenu : []}
                                                        name="permission[]"
                                                        style={{
                                                            display: "grid",
                                                            gridTemplateColumns: "1fr 1fr"
                                                        }}
                                                    />
                                                }
                                            </MDBox>
                                        </MDBox>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container justifyContent="center">
                                            <Grid item>
                                                <MDBox>
                                                    <UBKSubmit type="submit" value="Save" color="primary" />
                                                </MDBox>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        </MDBox>
                    </Card>
                </Grid>
            </Grid>
        </MDBox>
    );
}

export default EditAdmin;
